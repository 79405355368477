<template>
  <div class="sponsors">
    <HeroVideo />
    <div class="partnership-opportunities">
      <div class="title-partnership text-center py-4">
        <h1
          class="text-uppercase Sansrace-Regular mb-0"
          v-if="type == 'desktop'"
        >
          Partnership Opportunities
        </h1>
        <h3 class="text-uppercase Sansrace-Regular mb-0" v-else>
          Partnership Opportunities
        </h3>
      </div>
      <div class="detail py-4">
        <div class="container-xl">
          <p>
            Join us for CHI INNOVATE 2023, the premier healthcare event of the
            year, where we will explore the latest trends and perspectives in
            healthcare innovation and transformation. As the landscape of
            healthcare continues to evolve, it is critical to stay up-to-date
            with the latest developments and network with industry leaders.
          </p>
          <p>
            Themed “Building Bridges: Health & Social Care”, this year’s focus
            will be on breaking down traditional boundaries between the social
            and healthcare sectors, and collaborating with community partners to
            catalyse health and social changes.
          </p>
          <p>
            Be part of our unrivaled lineup of international speakers and
            engaging innovation showcase in inspiring the wider healthcare
            community to create sustainable progress in adopting and
            implementing innovation.
          </p>
        </div>
      </div>
      <!-- New Sponsor and Exhibitor section -->
      <div class="sponsor-exh-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-12 py-5 btn-outer-div">
              <div class="btn-wrapper">
                <button
                  class="btn-custom text-uppercase Sansrace-Regular"
                  @click="activeType = 'all'"
                >
                  All
                </button>
                <button
                  class="btn-custom text-uppercase Sansrace-Regular"
                  @click="activeType = 'sponsor'"
                >
                  Sponsors
                </button>
                <button
                  class="btn-custom text-uppercase Sansrace-Regular"
                  @click="activeType = 'exhibitor'"
                >
                  Exhibitors
                </button>
                <button
                  class="btn-custom text-uppercase Sansrace-Regular"
                  @click="activeType = 'ecosystem'"
                >
                  Ecosystem Partners
                </button>
              </div>
            </div>
          </div>
          <div class="row align-items-start">
            <div
          class="pb-4"
              v-for="(data, tindex) in activeSection.filter((d) => {
                return activeType == 'all' || d.category == activeType;
              })"
              :key="tindex"
              :class="{
                'col-12' : data.column == 'col-12',
                'col-sm-4 px-0': data.column == 'col-4'
              }"
          >
            <div class="col-12 border-bottom border-dark">
              <h3 v-if="data.hiddenName && type == 'desktop'" class="text-uppercase Sansrace-Regular mb-2 purple-clr">
                  {{ data.name }} <span class="text-white invisible">{{data.hiddenName}}</span>
                </h3>
                <h3 v-else class="text-uppercase Sansrace-Regular mb-2 purple-clr">
                  {{ data.name }}
                </h3>
            </div>
            <div class="col-12" v-if="!data.multi">
              <div class="row">
                <div
                  class="px-3 py-sm-2 py-1 text-center"
                  :class="{
                    'col-sm-4': data.type == 'silver',
                    'col-sm-3': data.category == 'exhibitor',
                  }"
                  v-for="(sponsor, index) in sponsors[data.type]"
                  :key="index"
                >
                  <a v-if="sponsor.link" :href="sponsor.link" target="_blank">
                    <img
                      :src="sponsor.img"
                      class="img-fluid text-center"
                      alt=""
                    />
                  </a>

                  <img
                    v-else
                    :src="sponsor.img"
                    class="img-fluid text-center"
                    alt=""
                  />
                  <div v-if="sponsor.dPopup">
                  <h6 v-if="type == 'desktop'" class="mb-0 pointer" @click="openSpeakerModal(sponsor.dPopup)"><span class="Sansrace-Regular">+</span> View more</h6>
                  <h6 v-else class="mb-0 pointer" @click="openSpeakerModal(sponsor.mPopup)"><span class="Sansrace-Regular">+</span> View more</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" v-else>
              <div
                class="row py-5"
                v-for="(sponsorArr, index) in sponsors[data.type]"
                :key="index"
              >
                <div class="col-12">
                  <h3 class="text-uppercase Sansrace-Regular mb-2">
                    {{ data.names[index] }}
                  </h3>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div
                      class="col-sm-3 px-3 py-sm-2 py-1 text-center"
                      v-for="(spon, ind) in sponsorArr"
                      :key="ind"
                    >
                      <a v-if="spon.link" :href="spon.link" target="_blank">
                        <img
                          :src="spon.img"
                          class="img-fluid text-center"
                          alt=""
                      /></a>

                      <img
                        v-else
                        :src="spon.img"
                        class="img-fluid text-center"
                        alt=""
                      />
                      <div v-if="spon.dPopup">
                      <h6 v-if="type == 'desktop'" class="mb-0 pointer" @click="openSpeakerModal(spon.dPopup)"><span class="Sansrace-Regular">+</span> View more</h6>
                      <h6 v-else class="mb-0 pointer" @click="openSpeakerModal(spon.mPopup)"><span class="Sansrace-Regular">+</span> View more</h6>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
        </div>
      </div>
      <!-- end section -->
      <div class="image-and-detail py-4">
        <div class="container-xl">
          <div class="row">
            <div class="col-sm-5 mb-sm-0 mb-3">
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      src="@/assets/img/sponsors/img/1.png"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="@/assets/img/sponsors/img/2.png"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="@/assets/img/sponsors/img/3.png"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="@/assets/img/sponsors/img/4.png"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="@/assets/img/sponsors/img/5.png"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="@/assets/img/sponsors/img/6.png"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-target="#carouselExampleControls"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-target="#carouselExampleControls"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </button>
              </div>
            </div>
            <div class="col-sm-7">
              <p>
                A gathering of healthcare professionals including C-Suite,
                Management and Clinical Executives, CHI INNOVATE 2023 offers
                unique opportunities to elevate your organisation’s profile in
                healthcare:
              </p>
              <ul>
                <li>
                  <p>
                    Sponsor speaker session(s) to reinforce your industry
                    position
                  </p>
                </li>
                <li>
                  <p>
                    Participate in the exhibition to profile your company as an
                    innovator in healthcare
                  </p>
                </li>
                <li>
                  <p>
                    Take up specialised and targeted sponsorships to strengthen
                    your brand message
                  </p>
                </li>
                <li>
                  <p>
                    Use the available digital platform for branding to further
                    extend your exposure
                  </p>
                </li>
                <li>
                  <p>
                    Opportunity to make product and partnership announcements
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="overall py-4 text-center">
        <img
          src="@/assets/img/sponsors/stats.png"
          class="img-fluid"
          alt=""
          v-if="type == 'desktop'"
        />
        <img
          src="@/assets/img/sponsors/stats-mobile.png"
          class="img-fluid"
          alt=""
          v-else
        />
      </div>
      <div class="industry py-4 text-center">
        <img
          src="@/assets/img/sponsors/industry.png"
          class="img-fluid"
          alt=""
          v-if="type == 'desktop'"
        />
        <img
          src="@/assets/img/sponsors/industry-mobile.png"
          class="img-fluid"
          alt=""
          v-else
        />
      </div>
    </div>
    <div class="contact py-5">
      <div class="container-xl">
        <p>
          CHI INNOVATE 2023 aims to inspire innovation and collaboration, and
          accelerate healthcare transformation at large. It is the place to
          raise your corporate profile, showcase the latest innovative
          solutions, engage with decision makers and eventual end-users.
        </p>
        <p>
          To discuss partnership and profile-building opportunities at CHI
          INNOVATE 2023, please contact: Ryan Jin, Business Development Manager,
          CHI INNOVATE 2023 at conference@chi.sg
        </p>
        <div class="text-center py-4">
          <div class="row">
            <div class="col-sm-3 col-9 mx-auto">
              <a
                href="mailto:Conference@chi.sg?subject=Enquiry on Sponsorship & Exhibition"
                class="text-decoration-none"
              >
                <img
                  src="@/assets/img/sponsors/btn.png"
                  class="img-fluid"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PopUp Modal -->
    <div
      class="modal fade"
      id="speakerModal"
      tabindex="-1"
      aria-labelledby="modalspeakerLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content border-0" style="background: transparent">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="color: #000"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <img :src="currentImg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- todo remove footer from here and move to app -->
  </div>
</template>

<script>
import $ from "jquery";
import HeroVideo from "@/components/hero.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "sponsors",
  components: {
    HeroVideo,
    Footer,
  },
  data() {
    return {
      type: "desktop",
      activeType: "all",
      currentImg: "",
      activeSection: [
        {
          type: "silver",
          name: "Silver Sponsor",
          category: "sponsor",
          multi: false,
          column: "col-12"
        },
        {
          type: "teabreak",
          name: "Tea Break Sponsor",
          category: "sponsor",
          multi: false,
          column: "col-4",
          hiddenName: 'Supporter'
        },
        {
          type: "community",
          name: "Community and Social Sector Supporter",
          category: "sponsor",
          multi: false,
          column: "col-4"
        },
        {
          type: "xxxx",
          name: "Lunch Panel Host",
          category: "sponsor",
          multi: false,
          column: "col-4",
          hiddenName: 'Sector and Supporter'
        },
        {
          type: "premium",
          name: "Premium Exhibitors",
          category: "exhibitor",
          multi: false,
          column: "col-12"
        },
        {
          type: "exhibitor",
          name: "Exhibitors",
          category: "exhibitor",
          multi: true,
          names: ["", "start up pods"],
          column: "col-12"
        },
        {
          type: "ecosystem",
          name: "Ecosystem Partners",
          category: "ecosystem",
          multi: false,
          column: "col-12"
        },
      ],
      sponsors: {
        silver: [
          {
            id: 1,
            name: "sliver",
            img: "/silver/1.png",
            link: "https://www.nec.com/",
            dPopup: "/silver/popup/1.png",
            mPopup: "/silver/popup/1-m.png"
          },
        ],
        community:[
          {
            id:1,
            name: "community",
            img: "/community/1.png",
            link: "https://www.singaporepools.com.sg/",
            dPopup: "/community/popup/1.png",
            mPopup: "/community/popup/1-m.png"
          }
        ],
        teabreak: [
          {
            id: 2,
            name: "tea break",
            img: "/tea break/1.png",
            link: "https://nhic.sg/web/",
            dPopup: "/tea break/popup/1.png",
            mPopup: "/tea break/popup/1-m.png"
          },
        ],

        xxxx: [
          {
            id: 3,
            name: "xxxx",
            img: "/xxxx/1.png",
            link: "https://intellect.co/",
            dPopup: "/xxxx/popup/1.png",
            mPopup: "/xxxx/popup/1-m.png"
          },
        ],
        premium: [
          {
            id: 4,
            name: "premium",
            img: "/premium/1.png",
            link: "https://www.nec.com/",
            dPopup: "/premium/popup/1.png",
            mPopup: "/premium/popup/1-m.png"
          },
          {
            id: 4,
            name: "premium",
            img: "/premium/2.png",
            link: "https://www.host.sg/",
            dPopup: "/premium/popup/2.png",
            mPopup: "/premium/popup/2-m.png"
          },
          {
            id: 4,
            name: "premium",
            img: "/premium/3.png",
            link: "https://e.huawei.com/en/industries/healthcare",
            dPopup: "/premium/popup/3.png",
            mPopup: "/premium/popup/3-m.png"
          },
          {
            id: 4,
            name: "premium",
            img: "/premium/4.png",
            link: "https://pixel.imda.gov.sg/",
            dPopup: "/premium/popup/4.png",
            mPopup: "/premium/popup/4-m.png"
          },
          {
            id: 4,
            name: "premium",
            img: "/premium/5.png",
            link: "https://www.zuehlke.com/en",
            dPopup: "/premium/popup/5.png",
            mPopup: "/premium/popup/5-m.png"
          },
        ],
        exhibitor: [
          [
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/1.png",
              link: "https://intellect.co/",
              dPopup: "/exhibitor/basic/popup/1.png",
              mPopup: "/exhibitor/basic/popup/1-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/2.png",
              link: "https://www.healthtec.sg/",
              dPopup: "/exhibitor/basic/popup/2.png",
              mPopup: "/exhibitor/basic/popup/2-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/5.png",
              link: "https://kilsaglobal.com/",
              dPopup: "/exhibitor/basic/popup/6.png",
              mPopup: "/exhibitor/basic/popup/6-m.png"
              
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/3.png",
              link: "https://medtechactuator.com/",
              dPopup: "/exhibitor/basic/popup/3.png",
              mPopup: "/exhibitor/basic/popup/3-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/6.png",
              dPopup: "/exhibitor/basic/popup/7.png",
              mPopup: "/exhibitor/basic/popup/7-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/ishine.png",
              link: "http://www.ishinecloud.sg",
              dPopup: "/exhibitor/basic/popup/8.png",
              mPopup: "/exhibitor/basic/popup/8-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/4.png",
              link: "https://newcastleaustralia.edu.sg/",
              dPopup: "/exhibitor/basic/popup/4.png",
              mPopup: "/exhibitor/basic/popup/4-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/7.png",
              dPopup: "/exhibitor/basic/popup/5.png",
              mPopup: "/exhibitor/basic/popup/5-m.png"
            },
          ],
          [
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/1.png",
              link: "https://aihealth.sg/",
              dPopup: "/exhibitor/start/popup/1.png",
              mPopup: "/exhibitor/start/popup/1-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/6.png",
              link: "https://en.ai-ms.com/",
              dPopup: "/exhibitor/start/popup/6.png",
              mPopup: "/exhibitor/start/popup/6-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/2.png",
              link: "https://www.arqon.com/",
              dPopup: "/exhibitor/start/popup/2.png",
              mPopup: "/exhibitor/start/popup/2-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/3.png",
              link: "https://carecam.ai/",
              dPopup: "/exhibitor/start/popup/3.png",
              mPopup: "/exhibitor/start/popup/3-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/4.png",
              link: "https://lifeweavers.org/",
              dPopup: "/exhibitor/start/popup/4.png",
              mPopup: "/exhibitor/start/popup/4-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/Sound-eye.png",
              link: "https://sound-eye.com/",
              dPopup: "/exhibitor/start/popup/7.png",
              mPopup: "/exhibitor/start/popup/7-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/5.png",
              link: "https://vidalsolution.com/",
              dPopup: "/exhibitor/start/popup/5.png",
              mPopup: "/exhibitor/start/popup/5-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/uray.png",
              link: "https://uray.ai/",
              dPopup: "/exhibitor/start/popup/9.png",
              mPopup: "/exhibitor/start/popup/9-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/onwards.png",
              link:"https://www.onwardhealthresearch.com/",
              dPopup: "/exhibitor/start/popup/8.png",
              mPopup: "/exhibitor/start/popup/8-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/start/reebee.png",
              link:"https://www.rebeehealth.com/",
              dPopup: "/exhibitor/start/popup/10.png",
              mPopup: "/exhibitor/start/popup/10-m.png"
            },
          ],
        ],
        ecosystem:[
            {
              id: 5,
              name: "exhibitor",
              img: "/ecosystem/1.png",
              link: "https://www.a-star.edu.sg/enterprise/innovation-platforms/a-startcentral",
              dPopup: "/ecosystem/popup/1.png",
              mPopup: "/ecosystem/popup/1-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/ecosystem/2.png",
              link: "https://www.healthtec.sg/",
              dPopup: "/ecosystem/popup/2.png",
              mPopup: "/ecosystem/popup/2-m.png"
            },
            {
              id: 5,
              name: "exhibitor",
              img: "/exhibitor/basic/3.png",
              link: "https://medtechactuator.com/",
              dPopup: "/exhibitor/basic/popup/3.png",
              mPopup: "/exhibitor/basic/popup/3-m.png"
            },
        ]
      },
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openSpeakerModal(link) {
      this.currentImg = link;
      $("#speakerModal").modal("show");
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sponsors {
  .Sansrace-Regular {
    font-family: "Sansrace-Regular";
  }
  .purple-clr{
    color: #BD4BFF !important;
  }
  .pointer{
    cursor: pointer;
  }
  .partnership-opportunities {
    .title-partnership {
      background-image: linear-gradient(
        135deg,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      color: #fff;
    }
    .btn-width {
      width: 100%;
    }

    .btn-index {
      z-index: 1;
    }
    .detail {
      background-image: linear-gradient(
        180deg,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      color: #fff;
      p {
        font-family: "Nexa-Light";
      }
    }
    .image-and-detail {
      background-color: #f6f2fc;
      p {
        font-family: "Nexa-Light";
        color: #000;
      }
      ul {
        li {
          color: rgb(254, 65, 197);
        }
        p {
          color: #000;
          font-family: "Nexa-Light";
        }
      }
    }
    .industry {
      background-color: #f6f2fc;
    }
  }
  .sponsor-exh-wrapper {
    .btn-wrapper {
      display: flex;
      position: relative;
      .btn-custom {
        width: 250px;
        height: 80px;
        display: flex;
        cursor: pointer;
        border: 0px;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 40px;
        font-size: 32px;
      }
      .btn-custom:nth-child(1) {
        background: #df68ff;
        z-index: 10;
      }
      .btn-custom:nth-child(2) {
        width: 300px;

        background: #bf4bff;
        z-index: 5;
        margin-left: -70px;
      }
      .btn-custom:nth-child(3) {
        width: 320px;
        background: #7f29fe;
        z-index: 4;
        margin-left: -70px;
      }
      .btn-custom:nth-child(4) {
        width: 470px;
        background: #4200A4;
        z-index: 0;
        margin-left: -70px;
      }
    }
  }
  .contact {
    p {
      font-family: "Nexa-Light";
      color: #fff;
    }
    background-image: linear-gradient(
      to right bottom,
      #6d77b9,
      #5b60a4,
      #49498e,
      #383379,
      #281e64,
      #21145b,
      #190a51,
      #120048,
      #120048,
      #120048,
      #120048,
      #120048
    );
  }

  @media screen and (max-width: 1200px) {
    .btn-wrapper{
      .btn-custom{
        font-size: 23px !important;
      }
    }
  }

   @media screen and (max-width: 990px) and (min-width: 600px){
    .btn-wrapper{
      .btn-custom{
        font-size: 20px !important;
      }
      .btn-custom:nth-child(2){
        padding-left: 50px !important;
      }
      .btn-custom:nth-child(3){
        padding-left: 50px !important;
      }
      .btn-custom:nth-child(4){
        padding-left: 50px !important;
      }
    }
  }
   @media screen and (max-width: 768px) {
    .btn-wrapper{
      .btn-custom:nth-child(2){
        width: 400px !important;
      }
      .btn-custom:nth-child(3){
        width: 440px !important;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .btn-outer-div{
      overflow-x: scroll;
    }
    .btn-wrapper {
      display: flex;
      position: relative;
      width: 700px !important;
      .btn-custom {
        width: 50% !important;
        height: 40px !important;
        display: flex;
        cursor: pointer;
        border: 0px;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 40px;
        font-size: 16px !important;
      }
      .btn-custom:nth-child(1) {
        background: #df68ff;
        z-index: 10;
      }
      .btn-custom:nth-child(2) {
        width: 60% !important;

        background: #bf4bff;
        z-index: 5;
        margin-left: -35px !important;
      }
      .btn-custom:nth-child(3) {
        width: 60% !important;
        background: #7f29fe;
        z-index: 0;
        margin-left: -35px !important;
      }
      .btn-custom:nth-child(4) {
        width: 100% !important;
        background: #7f29fe;
        z-index: 0;
        margin-left: -35px !important;
      }
    }
  }
}
</style>
